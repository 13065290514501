import {
  BlockStack,
  Box,
  Button,
  DatePicker,
  Icon,
  InlineGrid,
  InlineStack,
  OptionList,
  Popover,
  Scrollable,
  Select,
  TextField,
  useBreakpoints,
} from "@shopify/polaris";
import React, { useEffect, useRef, useState } from "react";
import { ArrowRightIcon, CalendarIcon } from "@shopify/polaris-icons";
import { Range } from "@shopify/polaris/build/ts/src/utilities/dates";
import moment from "moment";
import { useTranslation } from "react-i18next";

export interface InputDateRangePeriod {
  since?: string;
  until?: string;
}
export interface DateRange {
  title: string;
  alias: string;
  period: {
    since: Date;
    until: Date;
  };
}
interface Props {
  initialSelectedRangeIndex: number;
  ranges: DateRange[];
  onApply: (activeDateRange: DateRange) => void;
}

const monthDiff = (referenceDate: any, newDate: any) => {
  return (
    newDate.month -
    referenceDate.month +
    12 * (referenceDate.year - newDate.year)
  );
};

export default function DateRangeSelectorPopup({
  initialSelectedRangeIndex,
  ranges,
  onApply,
}: Props) {
  const { t } = useTranslation();
  const { mdDown, lgUp } = useBreakpoints();
  const shouldShowMultiMonth = lgUp;

  const [popoverActive, setPopoverActive] = useState(false);
  const [activeDateRange, setActiveDateRange] = useState<DateRange>(
    ranges[initialSelectedRangeIndex],
  );

  const [inputValues, setInputValues] = useState<InputDateRangePeriod>({});
  const [{ month, year }, setDate] = useState({
    month: activeDateRange.period.since.getMonth(),
    year: activeDateRange.period.since.getFullYear(),
  });

  const datePickerRef = useRef(null);
  const VALID_YYYY_MM_DD_DATE_REGEX = /^\d{4}-\d{1,2}-\d{1,2}/;

  function isDate(date: string) {
    return !Number.isNaN(new Date(date).getDate());
  }

  function isValidYearMonthDayDateString(date: string) {
    return VALID_YYYY_MM_DD_DATE_REGEX.test(date) && isDate(date);
  }

  function isValidDate(date: string) {
    return date.length === 10 && isValidYearMonthDayDateString(date);
  }

  function parseYearMonthDayDateString(input: string) {
    const [year, month, day] = input.split("-");

    return new Date(Number(year), Number(month) - 1, Number(day));
  }

  function formatDate(date: Date) {
    return moment(date).format("YYYY-MM-DD");
  }

  function nodeContainsDescendant(rootNode: Node, descendant: Node) {
    if (rootNode === descendant) {
      return true;
    }
    let parent = descendant.parentNode;
    while (parent != null) {
      if (parent === rootNode) {
        return true;
      }
      parent = parent.parentNode;
    }
    return false;
  }

  function isNodeWithinPopover(node: Node) {
    return datePickerRef?.current
      ? nodeContainsDescendant(datePickerRef.current, node)
      : false;
  }

  const handleStartInputValueChange = (value: string) => {
    setInputValues((prevState) => {
      return { ...prevState, since: value };
    });

    if (isValidDate(value)) {
      const newSince = parseYearMonthDayDateString(value);
      setActiveDateRange((prevState) => {
        const newPeriod =
          prevState.period && newSince <= prevState.period.until
            ? { since: newSince, until: prevState.period.until }
            : { since: newSince, until: newSince };
        return {
          ...prevState,
          period: newPeriod,
        };
      });
    }
  };
  const handleEndInputValueChange = (value: string) => {
    setInputValues((prevState) => ({ ...prevState, until: value }));
    if (isValidDate(value)) {
      const newUntil = parseYearMonthDayDateString(value);
      setActiveDateRange((prevState) => {
        const newPeriod =
          prevState.period && newUntil >= prevState.period.since
            ? { since: prevState.period.since, until: newUntil }
            : { since: newUntil, until: newUntil };
        return {
          ...prevState,
          period: newPeriod,
        };
      });
    }
  };
  const handleInputBlur = (event: React.FocusEvent) => {
    const isRelatedTargetWithinPopover =
      event.relatedTarget != null && isNodeWithinPopover(event.relatedTarget);

    if (isRelatedTargetWithinPopover) {
      return;
    }
    setPopoverActive(false);
  };

  const handleMonthChange = (month: number, year: number) => {
    setDate({ month, year });
  };

  const handleCalendarChange = ({ start, end }: Range) => {
    const newDateRange: DateRange = ranges.find((range) => {
      return (
        range.period.since.valueOf() === start.valueOf() &&
        range.period.until.valueOf() === end.valueOf()
      );
    }) || {
      alias: "custom",
      title: "Custom",
      period: {
        since: start,
        until: end,
      },
    };

    setActiveDateRange(newDateRange);
  };

  const onHandleApply = () => {
    setPopoverActive(false);

    onApply(activeDateRange);
  };

  const onHandleCancel = () => {
    setPopoverActive(false);
  };

  useEffect(() => {
    if (activeDateRange) {
      setInputValues({
        since: formatDate(activeDateRange.period.since),
        until: formatDate(activeDateRange.period.until),
      });

      const monthDifference = monthDiff(
        { year, month },
        {
          year: activeDateRange.period.until.getFullYear(),
          month: activeDateRange.period.until.getMonth(),
        },
      );
      if (monthDifference > 1 || monthDifference < 0) {
        setDate({
          month: activeDateRange.period.until.getMonth(),
          year: activeDateRange.period.until.getFullYear(),
        });
      }
    }
  }, [activeDateRange]);

  const buttonValue =
    activeDateRange.title === "Custom"
      ? `${activeDateRange.period.since.toDateString()} - ${activeDateRange.period.until.toDateString()}`
      : t(activeDateRange.title);

  return (
    <Popover
      active={popoverActive}
      autofocusTarget="none"
      preferredAlignment="left"
      preferredPosition="below"
      fluidContent
      sectioned={false}
      fullHeight
      activator={
        <Button
          size="slim"
          icon={CalendarIcon}
          onClick={() => setPopoverActive(!popoverActive)}
        >
          {buttonValue}
        </Button>
      }
      onClose={() => setPopoverActive(false)}
    >
      <Popover.Pane fixed>
        <InlineGrid
          columns={{
            xs: "1fr",
            md: "max-content max-content",
          }}
          gap="0"
        >
          <Box
            maxWidth={mdDown ? "516px" : "212px"}
            width={mdDown ? "100%" : "212px"}
            padding={{ xs: "500", md: "0" }}
            paddingBlockEnd={{ xs: "100", md: "0" }}
          >
            {mdDown ? (
              <Select
                label="dateRangeLabel"
                labelHidden
                onChange={(value) => {
                  const result = ranges.find(({ alias }) => alias === value);

                  setActiveDateRange(result as DateRange);
                }}
                value={
                  t(activeDateRange?.title) || activeDateRange?.alias || ""
                }
                options={ranges.map(({ alias, title }) => ({
                  label: t(title),
                  value: alias,
                }))}
              />
            ) : (
              <Scrollable style={{ height: "334px" }}>
                <OptionList
                  options={ranges.map((range) => ({
                    value: range.alias,
                    label: t(range.title),
                  }))}
                  selected={[activeDateRange.alias]}
                  onChange={(value) => {
                    setActiveDateRange(
                      ranges.find(
                        (range) => range.alias === value[0],
                      ) as DateRange,
                    );
                  }}
                />
              </Scrollable>
            )}
          </Box>

          <Box padding={{ xs: "500" }} maxWidth={mdDown ? "320px" : "516px"}>
            <BlockStack gap="400">
              <InlineStack gap="200">
                <div style={{ flexGrow: 1 }}>
                  <TextField
                    role="combobox"
                    label="Since"
                    labelHidden
                    prefix={<Icon source={CalendarIcon} />}
                    value={inputValues.since}
                    onChange={handleStartInputValueChange}
                    onBlur={handleInputBlur}
                    autoComplete="off"
                  />
                </div>
                <Icon source={ArrowRightIcon} />
                <div style={{ flexGrow: 1 }}>
                  <TextField
                    role="combobox"
                    label="Until"
                    labelHidden
                    prefix={<Icon source={CalendarIcon} />}
                    value={inputValues.until}
                    onChange={handleEndInputValueChange}
                    onBlur={handleInputBlur}
                    autoComplete="off"
                  />
                </div>
              </InlineStack>
              <div>
                <DatePicker
                  month={month}
                  year={year}
                  selected={{
                    start: activeDateRange.period.since,
                    end: activeDateRange.period.until,
                  }}
                  onMonthChange={handleMonthChange}
                  onChange={handleCalendarChange}
                  multiMonth={shouldShowMultiMonth}
                  allowRange
                />
              </div>
            </BlockStack>
          </Box>
        </InlineGrid>
      </Popover.Pane>
      <Popover.Pane fixed>
        <Popover.Section>
          <InlineStack align="end" gap="200">
            <Button onClick={onHandleCancel}>Cancel</Button>
            <Button variant="primary" onClick={onHandleApply}>
              Apply
            </Button>
          </InlineStack>
        </Popover.Section>
      </Popover.Pane>
    </Popover>
  );
}
